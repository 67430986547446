@font-face {
  font-family: "Euclid";
  src: local("Euclid"), url("../Assets//Fonts/Euclid Circular A Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Euclid";
  src: local("Euclid"), url("../Assets//Fonts/Euclid Circular A Light.ttf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "Euclid";
  src: local("Euclid"), url("../Assets//Fonts/Euclid Circular A Regular.ttf") format("truetype");
  font-weight: 400;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --yellow: #ffe01b;
  --yellow-shadow: #ecd018;
  --meadow: #0FC6A8;
  --meadow-shadow: #0caa8f;
  --stepper-color	:#333;
  --purple: #9F84BD;
  --purple-shadow: #846e9e;
  --umber: #65524D;
  --umber-shadow: #4c3e3a;
  --fx-xl: 5rem;
  --fx-600: 1.5rem;
  --fx-500: 1.25rem;
  --fx-400: 1rem;
  --fs1: font-size: clamp(1rem, 2.5vw, 1.1rem);
}

body {
  margin: 0;
  padding: 0;
  font-family: "Euclid", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  max-width: 2048px;
  margin: 0 auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  font-family: "Euclid";
}

::-webkit-scrollbar {
  width: 12px;
  height: 3px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #868E96;
}

#certStyle {
  width: 0;
  height: 0;
  scale: 0.5;
  margin: 0 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
}
#certStyle button {
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  font-size: 30px;
  padding: 1rem 2rem;
}
@media screen and (max-width: 900px) {
  #certStyle {
    scale: 0.35;
  }
  #certStyle button {
    margin-top: 3rem;
    scale: 1.5;
  }
}
@media screen and (max-width: 600px) {
  #certStyle {
    scale: 0.25;
  }
  #certStyle button {
    margin-top: 3rem;
    scale: 2;
  }
}

.mobileMenu {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
.mobileMenu .mobileMenuDivs {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
}

.catIcon {
  border-radius: 0.25rem;
  cursor: default;
  display: flex;
  grid-gap: 0.5rem;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  padding: 0.5rem 0.75rem;
}
.catIcon svg {
  margin: 0;
  padding: 0;
}
.catIcon:hover {
  background-color: #f8f8f8;
}

.drawerForChat .mantine-Drawer-overlay {
  background: rgba(0, 0, 0, 0.5333333333);
}
.drawerForChat .mantine-Drawer-drawer {
  margin: 2rem;
  border-radius: 0.5rem;
}
.drawerForChat img.convFiles {
  max-width: 250px;
  -o-object-fit: contain;
     object-fit: contain;
}
.drawerForChat video.convFiles {
  max-width: 350px;
  -o-object-fit: contain;
     object-fit: contain;
}
.drawerForChat .chatWrapper {
  height: 94%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.drawerForChat .chatWrapper .chatMessages {
  max-height: 500px;
  height: 500px;
  overflow: hidden;
  overflow-y: scroll;
}
.drawerForChat .chatWrapper .chatMessages::-webkit-scrollbar {
  display: none;
}
.drawerForChat .chatWrapper .chatMessages p {
  margin: 0;
  font-size: 15px;
}
.drawerForChat .chatWrapper .chatMessages .teacherMessage {
  margin: 1rem 0;
  margin-right: auto;
  width: 75%;
  background-color: #eee;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}
.drawerForChat .chatWrapper .chatMessages .teacherMessage:first-child {
  margin: 0;
  margin-right: auto;
}
.drawerForChat .chatWrapper .chatMessages .teacherMessage + .teacherMessage {
  margin: 0.25rem 0;
  margin-right: auto;
}
.drawerForChat .chatWrapper .chatMessages .studentMessage {
  margin: 1rem 0;
  margin-left: auto;
  width: 75%;
  background-color: #d9caec;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}
.drawerForChat .chatWrapper .chatMessages .studentMessage img {
  display: flex;
  margin: 0 auto;
  max-width: 100%;
  max-height: 200px;
  -o-object-fit: contain;
     object-fit: contain;
  background: #eee;
}
.drawerForChat .chatWrapper .chatMessages .studentMessage audio {
  display: flex;
  margin: 1.5rem auto 1rem auto;
  width: 100%;
}
.drawerForChat .chatWrapper .chatMessages .studentMessage video {
  height: 200px;
  max-width: 100%;
}
.drawerForChat .chatWrapper .chatMessages .studentMessage:first-child {
  margin: 0;
  margin-left: auto;
}
.drawerForChat .chatWrapper .chatMessages .studentMessage + .studentMessage {
  margin: 0.25rem 0;
  margin-left: auto;
}
.drawerForChat .chatWrapper form {
  margin-top: 1rem;
}
.drawerForChat .chatWrapper form .fileInputs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 0.5rem;
  margin-bottom: 0.5rem;
  align-items: center;
  justify-content: space-between;
}
.drawerForChat .chatWrapper form .fileInputs .fileInput {
  display: none;
}
.drawerForChat .chatWrapper form .fileInputs .filePart {
  justify-content: center;
  border: 1px solid #aaa;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  transition: all 0.15s;
}
.drawerForChat .chatWrapper form .fileInputs .filePart p {
  margin: 0;
  margin-left: 0.25rem;
}
.drawerForChat .chatWrapper form .fileInputs .filePart:hover {
  background-color: #eee;
}
.drawerForChat .chatWrapper form .filePreview button {
  position: absolute;
  background: none;
  color: #888;
  margin: 0.5rem;
  right: 5%;
  cursor: pointer;
  z-index: 10;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.drawerForChat .chatWrapper form .filePreview button:hover {
  border: 1px solid #888;
}
.drawerForChat .chatWrapper form img {
  display: flex;
  margin: 0 auto;
  width: 100%;
  height: 200px;
  -o-object-fit: contain;
     object-fit: contain;
  background: #eee;
}
.drawerForChat .chatWrapper form audio {
  display: flex;
  margin: 1.5rem auto 1rem auto;
  width: 100%;
}
.drawerForChat .chatWrapper form video {
  height: 200px;
  width: 100%;
}
.drawerForChat .chatWrapper form textarea {
  display: flex;
  position: relative;
  width: 94%;
  resize: none;
  border: 1px solid #aaa;
  border-radius: 0.25rem;
  padding: 0.5rem 0.75rem;
  outline: none;
  font-size: 15px;
}
.drawerForChat .chatWrapper form button.send {
  font-size: 15px;
  cursor: pointer;
  width: 100%;
  padding: 0.75rem 0;
  margin-top: 0.5rem;
  border: none;
  background: var(--yellow);
  border-radius: 0.25rem;
}
.drawerForChat .chatWrapper form button.send:hover {
  background: var(--yellow-shadow);
}

.listWrapper {
  margin: 0 0 2rem 0;
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
}
.listWrapper h2 {
  font-weight: 500;
}
.listWrapper .userListWrapper {
  max-height: 400px;
  height: 400px;
  overflow-y: scroll;
}
.listWrapper ::-webkit-scrollbar-track {
  border-radius: 1rem;
  background: rgba(126, 126, 126, 0);
}
.listWrapper ::-webkit-scrollbar-thumb {
  margin-left: 1rem;
  background: rgba(126, 126, 126, 0.5);
}
.listWrapper ::-webkit-scrollbar-thumb:hover {
  background: rgb(126, 126, 126);
}
.listWrapper .userListModal {
  scrollbar-width: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #eee;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
}
.listWrapper .userListModal .buttons {
  display: flex;
  align-items: center;
}
.listWrapper .userListModal .studentButton {
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  padding: 0rem 1.5rem;
  min-height: 50px;
  border: none;
  cursor: pointer;
  transition: all 0.15s;
  font-weight: 500;
  border-radius: 0;
}
.listWrapper .userListModal .delete {
  border-radius: 0 0.25rem 0.25rem 0;
  padding: 0 0.75rem;
}
.listWrapper .userListModal .add {
  border-radius: 0 0.25rem 0.25rem 0;
}
.listWrapper .userListModal .studentDiv {
  display: flex;
  grid-gap: 1rem;
  align-items: center;
}
.listWrapper .userListModal .studentDiv img {
  margin: 0;
  width: 50px;
  min-width: 50px;
  height: 50px;
  min-height: 50px;
  -o-object-fit: cover;
     object-fit: cover;
  transition: all 0.15s;
  border-radius: 0.5rem;
}
.listWrapper .userListModal .studentDiv h3 {
  border-radius: 0 0.25rem 0.25rem 0;
  transition: all 0.15s;
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin: 0;
}

.tsbutton {
  background-color: var(--yellow);
  color: #000;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
}
.tsbutton:hover {
  background-color: var(--yellow-shadow);
}/*# sourceMappingURL=index.css.map */