@font-face {
  font-family: "Euclid";
  src: local("Euclid"),
    url("../Assets//Fonts/Euclid\ Circular\ A\ Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Euclid";
  src: local("Euclid"),
    url("../Assets//Fonts/Euclid\ Circular\ A\ Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Euclid";
  src: local("Euclid"),
    url("../Assets//Fonts/Euclid\ Circular\ A\ Regular.ttf") format("truetype");
  font-weight: 400;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

:root{
  --yellow: #ffe01b;
  --yellow-shadow: #ecd018;
  --meadow: #0FC6A8;
  --meadow-shadow: #0caa8f;
  --purple: #9F84BD;
  --purple-shadow: #846e9e;
  --umber: #65524D;
  --umber-shadow: #4c3e3a;

  --fx-xl: 5rem;
  --fx-600: 1.5rem;
  --fx-500: 1.25rem;
  --fx-400: 1rem;

  --fs1: font-size: clamp(1rem, 2.5vw, 1.1rem);

}

body {
  margin: 0;
  padding: 0;
  font-family: "Euclid", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app{
  max-width: 2048px;
  margin: 0 auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: "Euclid";
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #343A40;
}

::-webkit-scrollbar-thumb {
  background: #495057;
}

::-webkit-scrollbar-thumb:hover {
  background: #868E96;
}

#certStyle{
  width:0;
  height:0;
  scale: 0.5;
  margin: 0 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  button{
    min-width:max-content;
    font-size: 30px;
    padding: 1rem 2rem;
  }
  @media screen and (max-width:900px){
    scale: 0.35;
    button{
      margin-top: 3rem;
      scale: 1.5;
    }
  }
  @media screen and (max-width:600px){
    scale: 0.25;
    button{
      margin-top: 3rem;
      scale: 2;
    }
  }
}

.mobileMenu{
  display: flex;
  flex-direction: column;
  padding: 1rem;
  .mobileMenuDivs{
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
  }
}

.catIcon{
  border-radius: .25rem;
  cursor: default;
  display: flex;
  grid-gap: .5rem;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  padding: .5rem .75rem;
  svg{
    margin: 0;
    padding: 0;
  }
  &:hover{
    background-color: #f8f8f8;
  }
}

.drawerForChat{
  .mantine-Drawer-overlay{
    background: #00000088;
  }
  .mantine-Drawer-drawer{
    margin: 2rem;
    border-radius: 0.5rem;
  }
  img.convFiles{
    max-width: 250px;
    object-fit: contain;
  }
  video.convFiles{
    max-width: 350px;
    object-fit: contain;
  }
  .chatWrapper{
    height: 94%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    -ms-overflow-style: none;
    scrollbar-width: none;
    .chatMessages{
      max-height: 500px;
      height: 500px;
      overflow: hidden;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      p{
        margin: 0;
        font-size: 15px;
      }

      .teacherMessage{
        margin: 1rem 0;
        margin-right: auto;
        width: 75%;
        background-color: #eee;
        padding: .75rem 1rem;
        border-radius: 0.5rem;
      }
      .teacherMessage:first-child{
        margin: 0;
        margin-right: auto;
      }
      .teacherMessage + .teacherMessage{
        margin: 0.25rem 0;
        margin-right: auto;
      }
      .studentMessage{
        margin: 1rem 0;
        margin-left: auto;
        width: 75%;
        background-color: #d9caec;
        padding: .75rem 1rem;
        border-radius: 0.5rem;
        img{
          display: flex;
          margin: 0 auto;
          max-width: 100%;
          max-height: 200px;
          object-fit: contain;
          background: #eee;
        }
        audio{
          display: flex;
          margin: 1.5rem auto 1rem auto;
          width: 100%;
        }
        video{
          height: 200px;
          max-width: 100%;
        }
      }
      .studentMessage:first-child{
        margin: 0;
        margin-left: auto;
      }
      .studentMessage + .studentMessage{
        margin: 0.25rem 0;
        margin-left: auto;
      }
    }
    form{
      margin-top: 1rem;
      .fileInputs{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: .5rem;
        margin-bottom: .5rem;
        align-items: center;
        justify-content: space-between;

        .fileInput{
          display: none;
        }
        .filePart{
          justify-content: center;
          border: 1px solid #aaa;
          border-radius: 0.25rem;
          display: flex;
          align-items: center;
          padding: 0.5rem .75rem;
          cursor: pointer;
          transition: all 0.15s;
          p{
            margin: 0;
            margin-left: 0.25rem;
          }
          &:hover{
            background-color: #eee;
          }
        }
      }
      .filePreview{
        button{
          position: absolute;
          background: none;
          color: #888;
          margin: .5rem;
          right: 5%;
          cursor: pointer;
          z-index: 10;
          border: 1px solid transparent;
          border-radius: 0.25rem;
          &:hover{
            border: 1px solid #888;
          }
        }
      }
      img{
        display: flex;
        margin: 0 auto;
        width: 100%;
        height: 200px;
        object-fit: contain;
        background: #eee;
      }
      audio{
        display: flex;
        margin: 1.5rem auto 1rem auto;
        width: 100%;
      }
      video{
        height: 200px;
        width: 100%;
      }
      textarea{
        display: flex;
        position: relative;
        width: 94%;
        resize: none;
        border: 1px solid #aaa;
        border-radius: 0.25rem;
        padding: .5rem .75rem;
        outline: none;
        font-size: 15px;
      }
      button.send{
        font-size: 15px;
        cursor: pointer;
        width: 100%;
        padding: 0.75rem 0;
        margin-top: 0.5rem;
        border: none;
        background: var(--yellow);
        border-radius: 0.25rem;
        &:hover{
          background: var(--yellow-shadow);
        }
      }
    }
  }
}

.listWrapper{
  margin: 0 0 2rem 0;
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  h2{
    font-weight: 500;
  }
  .userListWrapper{
    max-height: 400px;
    height: 400px;
    overflow-y: scroll;
  }

  ::-webkit-scrollbar-track {
    border-radius: 1rem;
    background: rgba(126, 126, 126, 0);
  }

  ::-webkit-scrollbar-thumb {
    margin-left: 1rem;
    background: rgba(126, 126, 126, 0.5);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(126, 126, 126, 1);
  }

  .userListModal{
    scrollbar-width: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #eee;
    border-radius: .25rem;
    margin-bottom: .5rem;
    .buttons{
      display: flex;
      align-items: center;
    }
    .studentButton{
      min-width: max-content;
      padding: 0rem 1.5rem;
      min-height: 50px;
      border: none;
      cursor: pointer;
      transition: all 0.15s;
      font-weight: 500;
      border-radius: 0;
    }
    .delete{
      border-radius: 0 0.25rem .25rem 0;
      padding: 0 0.75rem;
    }
    .add{
      border-radius: 0 0.25rem .25rem 0;
    }
    .studentDiv{
      display: flex;
      grid-gap: 1rem;
      align-items: center;
      img{
        margin: 0;
        width: 50px;
        min-width: 50px;
        height: 50px;
        min-height: 50px;
        object-fit: cover;
        transition: all .15s;
        border-radius: 0.5rem;
      }
      h3{
        border-radius: 0 0.25rem 0.25rem 0;
        transition: all .15s;
        font-weight: 500;
        font-size: 16px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin: 0;
      }
    }
  }
}

.tsbutton{
  background-color: var(--yellow);
  color: #000;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
  &:hover{
    background-color: var(--yellow-shadow);
  }
}